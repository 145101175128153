.filter {
	
	* {
		color: #424A50;
	}
	&:after {
		content: '';
		position: absolute;
		right: 14px;
		top: -4px;
		width: 8px;
		height: 8px;
		transform: rotate(45deg);
		background: white;
	}
}